import React, { useEffect } from 'react';
import { MailIcon } from '@heroicons/react/outline';
import { Helmet } from 'react-helmet';

import illu from '../../images/contact.svg';
import PageLayout from '../../layouts/Page';

const Contact = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '7665055',
          region: 'na1',
          formId: 'd7bd89a6-0aa7-4a5a-8dd9-7892dbe2935e',
          target: '#my-hubspot-form',
        });
      }
    });
  });

  return (
    <PageLayout>
      <Helmet>
        <title>Demande de démo Thank-You Analytics</title>
        <meta
          name="description"
          content="Pour une demande démo ou une question sur nos solutions, envoyez-nous un message."
        />
      </Helmet>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8 divide-y-2 divide-gray-200">
          <div>
            <h2 className="font-title text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              Demande de démo
            </h2>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
              <p className="text-xl text-gray-500">N'hésitez pas à nous contacter</p>
            </div>
          </div>

          <section className="relative mt-6">
            <div className="flex items-center sm:space-x-8">
              <div className="hidden sm:block flex-1">
                <img src={illu} className="w-full" alt="Demande de démo" />
              </div>

              <div className="flex-1 my-4 sm:my-0">
                <div id="my-hubspot-form"></div>
              </div>
            </div>
          </section>

          <div className="font-title relative overflow-hidden py-10 px-6 bg-gray-50 sm:px-10 xl:p-12">
            <p className="mt-6 text-base max-w-3xl">
              THANK-YOU ANALYTICS- THEBRIGADEFRANCE SAS,
              <br />
              38, Boulevard Carnot
              <br />
              Bureau 3,
              <br />
              59000, Lille
            </p>
            <dl className="mt-8 space-y-6">
              <dd className="flex text-base ">
                <MailIcon className="flex-shrink-0 w-6 h-6" aria-hidden="true" />
                <a href="mailto:contact@thank-you.io" className="ml-3">
                  contact@thank-you.io
                </a>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Contact;
